import React from "react";
import { Link } from "gatsby";
import ChevronLeft from "@material-ui/icons/ArrowBack";

import "../scss/App.scss";

import LogoSmartView from "../images/smartview.png";
import Print1 from "../images/SmartViewPrint1.webp";
import Print2 from "../images/SmartViewPrint2.webp";
import Print3 from "../images/SmartViewPrint3.webp";

function SmartView() {
  return (
    <main className="smartview">
      <header>
        <Link to="/">
          <ChevronLeft />
          Voltar
        </Link>
        <h1>Smart View v1.1</h1>
      </header>
      <section>
        <img
          src={LogoSmartView}
          align="left"
          alt="Logo do SmartView"
          className="start-image"
        />
        <p>
          O <b>Smart View</b> é uma plataforma de software para gestão,
          visualização e análise de dados e eventos que, quando correlacionados,
          são capazes de identificar comportamentos ou relacionamentos de baixa
          percepção quando analisados isoladamente.
        </p>
        <p>
          A solução atende requisitos para inteligência de negócios (Business
          Intelligence), segurança de dados, segurança pública e cidades
          inteligentes. Onde eventos são correlacionados e monitorados para
          aumento da capacidade de gestão e principalmente na rápida tomada de
          decisão, como resposta a incidentes ou informações relevantes sobre
          negócios, processos de gestão, bem-estar e segurança.
        </p>
        <figure className="center-image">
          <img
            src={Print2}
            align="center"
            className="central-image"
            alt="Exemplo da visualização de timelines"
          />
          <figcaption>
            Captura de tela da visualização de eventos em uma timeline
          </figcaption>
        </figure>
        <p>
          A inteligência artificial é componente central deste processo.
          Técnicas de aprendizado de máquina (Machine Learning e/ou Deep
          Learning) são utilizadas para identificar dados que possam esconder ou
          mascarar tendências e comportamentos relevantes para a organização.
        </p>
        <figure className="center-image">
          <img
            src={Print1}
            align="center"
            className="central-image"
            alt="Página de configuração de processadores"
          />
          <figcaption>
            Captura de tela da página de configuração de processadores
          </figcaption>
        </figure>
        <p>Principais características do Smart View:</p>
        <ul>
          <li>Visualização gráfica;</li>
          <li>Visualização com passagem do tempo ou timeline;</li>
          <li>Visualização de dados estruturados e não estruturados;</li>
          <figure className="center-image">
            <img
              src={Print3}
              align="center"
              className="central-image"
              alt="Modal de visualização de evento"
            />
            <figcaption>
              Captura de tela do modal de visualização de evento
            </figcaption>
          </figure>
          <li>Integração via REST API’s;</li>
          <li>Integração via Webhooks customizados;</li>
          <li>
            Autenticação via JSON Web Tokens, servidores RADIUS, MS Active
            Directory, Servidores Linux, Microsoft Azure, Microsoft 365 e redes
            sociais;
          </li>
          <li>Criação de fontes de dados e coletores independentes;</li>
          <li>Pesquisa, carga e filtro via scripts SQL;</li>
          <li>Varredura de bases de dados;</li>
          <li>Acesso à bases de dados diversas, tais como:</li>

          <ul>
            Microsoft SQL Server Enterprise 2012 e 2016;
            <li>IBM DB2 versão 10.0.0.0 ou superior;</li>
            <li>PostgreSQL;</li>
            <li>
              Microsoft Integration Services e Microsoft Analysis Services;
            </li>
            <li>Power Designer versão 14.0 ou superior;</li>
            <li>
              Oracle Business Intelligence Enterprise Edition Plus (OBIEE)
              11.x.y.w.z ou superior;
            </li>
            <li>Oracle Data Integrator (ODI) série 11.x.y.w.z ou superior;</li>
            <li>
              Oracle Enterprise Data Quality (EDQ) versão 12.z.y.w.z ou
              superior;
            </li>
            <li>Microsoft Power BI;</li>
            <li>Microsoft Office 365;</li>
            <li>Flyway Community Edition 5 ou superior.</li>
          </ul>
          <li>Instalação e deploy On-premisses ou nuvem;</li>
          <li>Execução em containers Docker;</li>
          <li>Interface Web;</li>
          <li>
            Integração com fontes e ferramentas externas para dados e metadados;
          </li>
          <li>
            Uso de tags para identificar dados, eventos, ações, situações e
            decisões;
          </li>
          <li>Linhagem de dados;</li>
          <li>
            Identificação de padrões de dados (endereço, telefones, números de
            documentos oficiais, nomes de pessoas, nomes de empresas, valores
            monetários, tipos de dados etc.);
          </li>
          <li>Indicadores de frequência de dados e padrões;</li>
          <li>Inferência;</li>
          <li>Classificação de dados, metadados e campos customizados;</li>
          <li>Filtro e pesquisa de dados, metadados e campos customizados;</li>
          <li>
            Produção de documentação completa dos processos de análise e carga
            de dados, pesquisa e filtros de dados, nome dos responsáveis,
            processos, tags e campos customizados;
          </li>
          <li>
            Integração para importação, exportação e pesquisa em arquivos CSV
            para dados, metadados e documentação;
          </li>
          <li>Atendimento a Lei Geral de Proteção de Dados (LGPD);</li>
          <li>Criptografia de dados;</li>
          <li>
            Anonimização (tokenização) de dados de forma automática ou via
            padrões customizados;
          </li>
          <li>
            Manipulação para troca, extração, inserção, concatenação e
            anonimização de textos;
          </li>
          <li>
            Ambientes de Produção, Homologação, Desenvolvimento, Testes e
            Simulações;
          </li>
          <li>
            Geração e execução de processos (binários) executáveis e
            independentes (autocontidos) para importação, exportação, carga,
            pesquisa, análise e filtro em ambientes Linux, Windows e macOS.
          </li>
        </ul>
      </section>
    </main>
  );
}

export default SmartView;
